<template>
  <div class="border-top-info border-3 border-table-radius px-0">
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="filter"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refresh"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refTableList"
          small
          :items="getAllData"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          class="overflow-y"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(debt)="data">
            $ {{ data.item.debt | currency }}
          </template>
          <template #cell(courrier_types)="data">
            <span class="d-flex py-1 justify-content-center">
              <p class="mr-1">{{ data.item.courrier_types }}</p>
              <feather-icon
                :icon="
                  data.item.courrier_types ? 'Edit2Icon' : 'PlusCircleIcon'
                "
                :class="[
                  data.item.courrier_types ? 'text-warning' : 'text-primary',
                ]"
                size="20"
                style="cursor: pointer"
                @click="openModal(data.item)"
              />
            </span>
          </template>
          <template #cell(track_number)="data">
            <template v-if="!data.item.editingTrackNumber">
              <template v-if="!data.item.track_number">
                <div
                  class="
                    text-center
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                  style="padding: 10px"
                  v-if="
                    data.item.courrier_types_id != 1 &&
                    data.item.courrier_types_id != null
                  "
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="20"
                    class="cursor-pointer text-primary"
                    @click="data.item.editingTrackNumber = true"
                  />
                </div>
              </template>
              <template v-else>
                <div class="d-flex align-items-center justify-content-center">
                  <span class="mt-1">
                    <p class="mr-1">{{ data.item.track_number }}</p>
                  </span>
                  <span>
                    <feather-icon
                      icon="Edit2Icon"
                      class="text-warning cursor-pointer"
                      size="20"
                      @click="data.item.editingTrackNumber = true"
                    />
                  </span>
                </div>
              </template>
            </template>
            <div
              class="d-flex align-items-center justify-content-center py-1"
              v-else
            >
              <b-form-input
                size="sm"
                style="width: 150px"
                v-model="data.item.track_number_for_edit"
              />
              <span class="ml-1">
                <feather-icon
                  icon="CheckIcon"
                  size="20"
                  class="cursor-pointer text-success"
                  @click="updateMarketingLettersTracking(data.item)"
                />
                <feather-icon
                  icon="XIcon"
                  size="20"
                  class="cursor-pointer text-danger"
                  @click="cancelEditTrackNumber(data.item)"
                />
              </span>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
            >
              <span>
                {{ data.item.created_at | myGlobalDay }}
              </span>
            </div>
          </template>
          <template #cell(seen_pdf)="data">
            <div class="d-flex justify-content-center">
              <a
                :class="
                  data.item.seen_pdf == 0 ? 'float-left' : 'opa float-left'
                "
                target="_blank"
                @click="openPdf(data.item)"
              >
                <amg-icon
                  class="cursor-pointer bigger text-danger"
                  icon="FilePdfIcon"
                />
              </a>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="py-1 d-flex justify-content-center">
              <b-button
                variant="success"
                size="sm"
                @click="sent(data.item)"
                :disabled="!validaButtonSent(data.item)"
                >Sent</b-button
              >
            </div>
          </template>
          <template #cell(zip_code)="data">
            <span> {{ data.item.state }} {{ data.item.zip_code }} </span>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-selection
      v-if="modalSelectionState"
      :data-client="dataItem"
      @hideModal="closeModal"
      @refreshTable="setCourrierType"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import fields from "../data/fields.pending.data";
import filters from "../data/filters.pending.data";
import ModalSelection from "./ModalSelection.vue";
import MarketingLettersService from "../../service/marketing-letters.service";

export default {
  components: {
    ModalSelection,
  },
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "entity_name",
      sortDesc: false,
      fields,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: filters,
      dataItem: {},
      modalSelectionState: false,
      _agent_type: "",
      flagButtonSent: false,
    };
  },
  created() {
    this.getAllShipmentTypes();
    if (this.getAgentType === 5 || this.getAgentType === 6) {
      this.fields.find(
        (element) => element.key === "entity_num"
      ).visible = false;
    } else {
      this.fields.find(
        (element) => element.key === "entity_num"
      ).visible = true;
    }
    this.fields.find((element) => element.key === "court").visible =
      this.getAgentType === 6;
    this.fields.find((element) => element.key === "case").visible =
      this.getAgentType === 6;
      this.fields.find((element) => element.key === "plaintiff").visible =
      this.getAgentType === 6;
      this.fields.find((element) => element.key === "debt").visible =
      this.getAgentType === 6;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      // transactions: "LogisticTransactionStore/G_TRANSACTION",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    getAgentType() {
      return this.$route.meta.pendingTab ===
        "correspondence-marketing-letters-corporate-agent-pending"
        ? 4
        : this.$route.meta.pendingTab ===
          "correspondence-marketing-letters-keybook-pending"
        ? 5
        : 6;
    },
  },
  methods: {
    async getAllShipmentTypes() {
      const response = await MarketingLettersService.getAllShipmentTypes();

      let firstOption = {
        value: "All",
        id: 0,
      };
      let newData = response.map((item) => ({
        value: item.name,
        id: item.id,
      }));
      newData.unshift(firstOption);
      this.filter[2].options = newData;
    },
    validaButtonSent(data) {
      if (data.courrier_types_id == 1 && data.seen_pdf == 1) {
        return true;
      } else if (
        (data.courrier_types_id == 2 || data.courrier_types_id == 3) &&
        data.track_number &&
        data.seen_pdf == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    cancelEditTrackNumber(data) {
      this.$set(data, "editingTrackNumber", false);
      this.$set(data, "track_number_for_edit", data.track_number);
    },
    async updateMarketingLettersTracking(data) {
      this.addPreloader();
      try {
        await MarketingLettersService.updateMarketingLettersTracking({
          id: data.id,
          track_number: data.track_number_for_edit,
        });
        this.$set(data, "track_number", data.track_number_for_edit);
        this.$set(data, "editingTrackNumber", false);
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal(error);
        this.$set(data, "track_number_for_edit", data.track_number);
        this.$set(data, "editingTrackNumber", true);
        this.removePreloader();
        console.error(error);
      }
    },
    async openPdf(data) {
      try {
        this.addPreloader();
        const result = await MarketingLettersService.updateSeenPdf({
          data: data,
        });
        if (result) {
          this.forceFileDownload(result.data, "document.pdf", "blank");
          this.removePreloader();
          this.$set(data, "seen_pdf", 1);
          // this.refresh();
        } else {
          this.removePreloader();
          this.showErrorSwal();
        }
      } catch (error) {
        console.error(error);
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    openModal(data) {
      this.modalSelectionState = true;
      this.dataItem = data;
    },
    closeModal() {
      this.modalSelectionState = false;
    },
    refresh() {
      this.$refs.refTableList.refresh();
    },
    setCourrierType(data, selectedItem, trackNumber) {
      this.$set(data, "courrier_types_id", selectedItem.id);
      this.$set(data, "courrier_types", selectedItem.name);
      this.$set(data, "track_number", trackNumber);
      this.$set(data, "track_number_for_edit", trackNumber);
    },
    async sent(data) {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id: data.id,
            user_id: this.currentUser.user_id,
          };
          this.addPreloader();
          const response = await MarketingLettersService.sendMarketingLetters(
            params
          );
          this.removePreloader();
          if (response.status == 200) {
            this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
            this.refresh();
          } else {
            this.showErrorSwal();
          }
        } catch (error) {
          console.error(error);
          this.removePreloader();
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong!"
          );
        }
      }
    },
    async getAllData(ctx) {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          orderby: ctx.sortBy,
          order: ctx.sortDesc ? "desc" : "asc",
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          view_pdf: this.filter[3].model == 3 ? null : this.filter[3].model,
          courrier_type:
            this.filter[2].model == 0 ? null : this.filter[2].model,
          agent_type: this.getAgentType,
          tab_type: 1,
        };
        const response = await MarketingLettersService.getMarketingLetters(
          params
        );
        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.data.last_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        return response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.opa {
  opacity: 0.5;
}
</style>
