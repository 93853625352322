<template>
  <b-modal
    ref="myModal"
    v-model="modalCenter"
    scrollable
    centered
    modal
    size="md"
    header-class="p-0"
    no-close-on-backdrop
    header-bg-variant="transparent border-bottom border-bottom-2"
    modal-class="modal-primary"
    title="SELECTION"
    title-class="h2 text-white"
    @hidden="hideModal"
  >
    <template v-slot:modal-header>
      <modal-header
        :dataItem="dataClient"
        :title="'SELECT TYPE'"
        @close="hideModal()"
      >
      </modal-header>
    </template>
    <b-row class="px-1">
      <b-col cols="6">
        <div>Type</div>
        <b-form-select
          v-model="selected"
          :options="options"
          text-field="name"
          value-field="id"
          @change="selectType()"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-2 px-1" v-if="!flagInputHidden">
      <b-col cols="6">
        <b-form-group label="Track Number">
          <b-form-input
            placeholder="Number"
            v-model="track_number"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-button variant="primary" @click="save()" :disabled="flagButtonDisabled"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>
<script>
import ModalHeader from "./ModalHeader.vue";
import MarketingLettersService from "@/views/correspondence/views/marketing-letters/service/marketing-letters.service";
export default {
  components: {
    ModalHeader,
  },
  props: {
    dataClient: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: false,
      selected: null,
      // selected: "1",
      options: [{ name: "Select type", id: null }],
      flagInputHidden: true,
      flagButtonDisabled: true,
      track_number: null,
    };
  },
  created() {
    this.modalCenter = true;
    this.getAllShipmentTypes();
  },
  methods: {
    async getAllShipmentTypes() {
      const types = await MarketingLettersService.getAllShipmentTypes();
      this.options.push(...types);
      this.selected = this.dataClient.courrier_types_id;
      this.track_number = this.dataClient.track_number;
      this.selectType();
    },
    async save() {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id: this.dataClient.id,
            type_courrier: this.selected,
            track_number: this.track_number,
          };
          this.addPreloader();
          const response = await MarketingLettersService.updateMarketingLetters(
            params
          );
          this.removePreloader();
          if (response.status == 200) {
            this.hideModal();
            this.$emit(
              "refreshTable",
              this.dataClient,
              this.options.find((item) => item.id == this.selected),
              this.selected == 1 ? null : this.track_number
            );
            this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
          } else {
            this.showErrorSwal();
          }
        } catch (error) {
          console.error(error);
          this.removePreloader();
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong!"
          );
        }
      }
    },
    hideModal() {
      this.$refs['myModal'].hide();
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    selectType() {
      if (this.selected === null) {
        this.flagButtonDisabled = true;
        this.flagInputHidden = true;
      } else if (this.selected == "1") {
        this.flagInputHidden = true;
        this.flagButtonDisabled = false;
      } else if (this.selected == "2" || this.selected == "3") {
        this.flagInputHidden = false;
        this.flagButtonDisabled = false;
      }
    },
  },
};
</script>
<style></style>
